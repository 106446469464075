* > * {
    margin: 0;
}

:root{
    --color-dark: #2C3131;
    --color-light: #F3F3F2;
}

#main{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
}

.board {
    text-align: center;
}

.place{
    margin: 1em;
}

.board .leaderboard{
    margin-bottom: 1em;
}

.board .duration{
    display: flex;
    justify-content: center;
    gap: 1em;
}

.board .duration > button{
    border: 1px solid var(--color-light);
    padding: .5em 2em;
    border-radius: 50px;
    background-color: transparent;
    cursor: pointer;
}

.board .duration > button:hover{
    background-color: var(--color-dark);
    color: var(--color-light);
}

.active{
    background-color: var(--color-dark);
    color: var(--color-light);
}

#profile{
    /* display: flex; */

    margin-top: 3em;
}

#profile .flex{
    display: flex;
    justify-content: space-around;
    gap: 1em;
    text-align: left;
    margin-bottom: 2em;
    border-image: linear-gradient(white, black) 30;
    border-width: 4px;
    border-bottom: solid;

}

#profile .flex .item{
    display: flex;
    align-items: center;
    
}

#profile .flex img{
    width: 50px;
    border-radius: 50%;
}

#profile .flex .info{
   padding: 1em;
}

.text-dark{
    color: var(--color-dark);
}

