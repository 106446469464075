input {
    width: 50px;
    height: 25px; 
    margin: 1em;   
}

label {
    color: white;
    
    
}

h3 {
    color: white;
}

.main-form {
    
   
    margin: auto;
    padding: 50px;
    margin-top: 40px;
    background-color: black;
    color: white;
    border-radius: 20px;
}

@media screen and (max-width: 768px) {
    .main-form {
      margin: 1em;
      padding-top: 1em;
    }
  }

.codeSubmission {
    
    border-radius: 50px;
    border: none;
    margin: 1em;
    font-size: 20px;
    background-image: linear-gradient(to right, #7e046d 0%, #ff0000 51%, #ffce2e 100%);
}