.main-dash {
    
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    
}

.profile {
      
    justify-content: center;
    align-items: center;
    padding-top: 30px;

}

.aviDash {
    width: 80;
    border-radius: 50%;
}

.stats {
    display: flex;
    justify-content: space-around;
}




h3 {
    color: white;
}